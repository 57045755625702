import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Previewable from '../components/Previewable'
import Layout from '../components/Layout'
import './casestudy.scss'
import SEO from '../components/SEO'
import { decodeEntities } from '../utils/htmlParse'
import RenderContent from '../components/RenderContent'
import { Hero, Cta } from '../acf/'
import { Image } from '../components/Image'
import Slider from 'react-slick'

class CaseStudyTemplate extends Component {
  state = {
    currentSlide: 1,
    isMobileView: false,
    isDotsVisible: false,
    isArrowsVisible: true,
    mobileBreakPoint: 550,
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('resize', this.setMobileDotsState, false);
      window.addEventListener('orientationchange', this.setMobileDotsState, false);
    }
    if (this.state.isMobileView) {
      this.setState({isDotsVisible: true, isArrowsVisible: false})
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobileDotsState, false)
    window.removeEventListener('orientationchange', this.setMobileDotsState, false);
  }

  setMobileDotsState = () => {
    let breakPoint = this.state.mobileBreakPoint;
    if (window) {
      let windowWidth = window.innerWidth;
      if ((windowWidth) < breakPoint) {
        this.setState({
          isMobileView: true,
          isDotsVisible: true,
          isArrowsVisible: false,
        })
      } else if (windowWidth > breakPoint) {
        this.setState({
          isMobileView: false,
          isDotsVisible: false,
          isArrowsVisible: true,
        })
      }
    }
  }

  render() {
    const {
      acf,
      commodityString,
      sectorString,
      locationString,
      title,
      featuredImage,
      featuredImageGs,
    } = this.props
    // Destructure your acf fields in this block
    const {
      summary,
      content,
      contentTwo,
      interjectImage,
      totalRevenue,
      totalWeeks,
      yearCompleted,
      gallery,
      infoPanel,
      testimonialBlock,
    } = acf

    // Visit https://react-slick.neostack.com/docs/api for reference
    const sliderSettings = {
      dots: this.state.isDotsVisible,
      arrows: this.state.isArrowsVisible,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: (oldIndex, newIndex) => {
        this.setState({ currentSlide: newIndex + 1 })
      },
    }
    const titleAndCommodityString = title;
    return (
      <section className="case-study-content">
        <div className="wrapper">
          <div className="content-overlay">
            <div className="flex-row position-relative slider-container">
              <div className="content content-side">
                {gallery && gallery.length > 0 ? (
                  <div className="gallery">
                    <Slider {...sliderSettings}>
                      {gallery &&
                        gallery.map((image, index) => (
                          <div key={index} className="item">
                            <Image className="background-image" position="relative" src={image} />
                          </div>
                        ))}
                    </Slider>
                    <div className="project-name">
                      {titleAndCommodityString ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: titleAndCommodityString,
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                    {gallery.length > 1 ? (
                      <div className="slide-counter">
                        {this.state.currentSlide}/{gallery.length}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div className="gallery">
                    <Slider {...sliderSettings}>
                      <div className="item">
                        {featuredImage && <Image src={featuredImage} position="relative" />}
                      </div>
                    </Slider>
                    <div className="project-name">
                      {titleAndCommodityString ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: titleAndCommodityString,
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-row">
                <div className="content sidebar">
                  {infoPanel && infoPanel.length > 0 ?
                    <div className="info-panel">
                      <InfoPanel props={infoPanel[0]} />
                    </div>
                  : null}
                </div>
              <article className={'case-study__article'}>
                {summary && <RenderContent
                  content={summary}
                  className={'case-study__summary'}
                />}
                {content && <RenderContent content={content} />}
                <div className="image-wrapper">
                  {interjectImage && testimonialBlock && testimonialBlock.testimonialText.length > 1 &&
                    <div className="image-wrapper__interject">
                      <div className="testimonial__wrapper">
                      <Testimonial props={testimonialBlock} />
                      </div>
                      <Image src={interjectImage} position="relative" />
                    </div>
                  }
                  {!testimonialBlock && interjectImage &&
                    <div className="image-wrapper__interject">
                      <Image src={interjectImage} position="relative" />
                    </div>
                  }
                </div>
                {contentTwo && <RenderContent content={contentTwo} />}
              </article>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const Testimonial = props => {
  const { testimonialText, author, authorCompany } = props.props;
  return (
    <div className="testimonial-content">
      <div className="testimonial-copy">
        {testimonialText}
      </div>
      <span className="testimonial-author">{author}</span>
      <span className="testimonial-company">{authorCompany}</span>
    </div>
  )
}

const InfoPanel = props => {
  if (typeof(props.props) == 'undefined') {
    return null;
  } else {
    const { title, valueLabelGroup } = props.props
    const values = valueLabelGroup.map((items, index) => (
      <div key={index} className={'info-panel-values'}>
        <p className={'info-panel__value value'}>{items.value}</p>
        <p className={'info-panel__label subtext'}>{items.valueLabel}</p>
        <span className="info-panel__bottom-bar"></span>
      </div>
    ))
    return (
      <div className={'info-panel__container'}>
        <div className="info-panel__title project-highlights-title">{title}</div>
        <div className={'info-panel-values__wrapper'}>{values}</div>
      </div>
    )
  }
}

const CaseStudy = props => {
  const { data, location } = props
  const {
    wordpressPost: post,
    allLocation,
    allSector,
    allCommodity,
    site,
  } = data
  if (!post) return null

  const { title: siteTitle } = site.siteMetadata
  const { slug, title, yoast, featuredImage, featuredImageGs, acf } = post
  const locationString =
    post.location &&
    post.location
      .map(
        cs => allLocation.edges.find(l => l.node.wordpress_id == cs).node.name
      )
      .join(', ')

  // sectorString will return a join of all sub-sectors without a parent sector provided,
  // e.g. if case study post has a child sector set as primary.
  const sectorString =
    post.sector &&
    post.sector
      .map(cs => allSector.edges.find(l => l.node.wordpress_id == cs).node.name)
      .join(' / ')

  // parent sector category will have a value of wordpress_parent: 0

  let parentSectorString = post.sector && post.sector.map(function(cs) {
    return allSector.edges.find(function(l) {
      if (l.node.wordpress_parent === 0) {
        return l.node.wordpress_id === cs
      }
    })
  })
  if (parentSectorString) {
    for (let i = 0; i < parentSectorString.length; i++) {
      // prevent uncaught type error by checking for undefined
      if (typeof parentSectorString[i] !== 'undefined') {
        if (parentSectorString[i].node) {
          parentSectorString = parentSectorString[i].node.name
        }
      }
    }
  }

  let commodityString =
    post.commodity &&
    post.commodity
      .map(
        cs => allCommodity.edges.find(l => l.node.wordpress_id == cs).node.name
      )
      .join(', ')

  const isCaseStudy = true;
  return (
    <Layout location={location} pageTitle={post.title}>
      <SEO
        title={
          yoast.metaTitle
            ? decodeEntities(yoast.metaTitle)
            : `Case Study - ${title}`
        }
        desc={yoast.metaDescription}
      />
      <Hero
        className={'case-study-hero'}
        backgroundImage={featuredImageGs ? featuredImageGs : featuredImage}
        subheading={
          parentSectorString && parentSectorString.length > 1 ? parentSectorString : sectorString
        }
        heading={title}
        blurb={`<span class="case-study-blurb">${
          locationString ? `<span>${locationString}</span>` : ''
        } ${commodityString ? `<span>${commodityString}</span>` : ''}</span>`}
        isCaseStudy={isCaseStudy}
      />
      <CaseStudyTemplate
        featuredImage={featuredImage}
        acf={acf}
        title={title}
        commodityString={commodityString}
        sectorString={sectorString}
        locationString={locationString}
      />
      <Cta
        heading="We’re like a family. A really big, really smart family."
        content="<strong>It takes different people with different ideas to make a difference.</strong><br/><br/> If you love exploring ideas, you’ll thrive within our extended global family, whether you’re a veteran problem-solver or a fresh graduate."
        button="/contact-us/"
        buttonHash=""
        buttonText="Stretch Yourself"
        backgroundImage={featuredImageGs ? featuredImageGs : featuredImage}
      />
    </Layout>
  )
}

export default Previewable(CaseStudy, 'case_studies')

export const pageQuery = graphql`
  fragment CaseStudyFields on wordpress__wp_case_studies {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query CaseStudyByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wordpressPost: wordpressWpCaseStudies(id: { eq: $id }) {
      slug
      title
      content
      sector
      location
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      featuredImage: featured_image_url {
        source_url
      }
      featuredImageGs: featured_image_url {
        source_url
      }
      acf {
        summary
        content
        contentTwo
        totalRevenue
        totalWeeks
        yearCompleted
        companyName
        gallery {
          source_url
        }
        infoPanel {
          title
          valueLabelGroup {
            value
            valueLabel
          }
        }
        interjectImage {
          source_url
        }
        isFeatured
        testimonialBlock {
          testimonialText
          author
          authorCompany
        }
      }
    }
    allLocation: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          wordpress_id
          wordpress_parent
        }
      }
    }
    allSector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
